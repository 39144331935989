import axios from 'axios';


const axiosBase = axios.create({
	xsrfHeaderName: 'X-CSRF-Token',
	withCredentials: true
});

const axiosFileBase = axios.create({
	xsrfHeaderName: 'X-CSRF-Token',
	withCredentials: true,
	'Content-Type':'multipart/form-data',
});

class Ajax{

	constructor(){
		this.ajaxType = null;
		this.token = null;
		this.requestPath = null;
	}

	async get(url,data){
		const params = this.createSendBaseData(data);
		const res = await axiosBase.get(url, params)
			.catch(err => {
				console.log(err);
				return err.response;
			 });
		return res;
	}

	async post(url,data){
		const params = this.createSendBaseData(data);
		const res = await axiosBase.post(url, params)
			.catch(err => {
				console.log(err);
				return err.response;
			 });
		return res;
	}

	async customPost(url,params){
		params.set('token',this.token);
		const res = await axiosBase.post(url, params)
			.catch(err => {
				console.log(err);
				return err.response;
			 });
		return res;
	}

	async filePost(url,params){
		params.append('token',this.token);
		const res = await axiosFileBase.post(url,params)
			.catch(err => {
				console.log(err);
				return err.response;
			});
		return res;
	}

	setToken(token){
		this.token = token;
	}

	setAjaxType(ajaxType){
		this.ajaxType = ajaxType;
	}

	setRequestPath(requestPath){
		this.requestPath = requestPath;
	}

	createSendBaseData(data){
		const params = new URLSearchParams();
		params.set('token',this.token);
		params.set('ajaxType',this.ajaxType);
		params.set('data',JSON.stringify(data));
		return params;
	}

}

export default new Ajax();

